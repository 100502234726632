import React from 'react';

const ClickableDiv = (props) => {

  function handleKeyDown(e) {
    if(props.preventDefault) {
      e.preventDefault();
    }
    if(props.stopPropagation) {
      e.stopPropagation();
    }
    if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
      props.onClick?.();
    }
  }

  return (
    <div
      {...props}
      role="button"
      aria-pressed="false"
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={handleKeyDown}
    >
      {props.children}
    </div>
  );
};

export default ClickableDiv;
