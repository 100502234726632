import React from 'react';
import Layout from '../components/Layout';
import Masonry from 'react-masonry-css';
import portfolioJson from '../data/portfolio.json';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import ClickableDiv from '../components/ClickableDiv';
import { Captions } from 'yet-another-react-lightbox/plugins';
import "yet-another-react-lightbox/plugins/captions.css";

const PortfolioPage = () => {
  const [index, setIndex] = React.useState(-1);
  return (
    <Layout pageWrapper="page-portfolio">
      <section className="main-padding mt-0 mt-lg-5">
        <div className="container">
          <Masonry
            breakpointCols={{
              default: 3,
              500: 1,
            }} className="project-masonry" columnClassName="project-masonry-item"
          >
            {portfolioJson.items.map((item, i) => (
              <ClickableDiv
                key={`portfolio-${i}`}
                onClick={() => setIndex(i)}
              >
                <img className="img-fluid" src={item.imgSrc} alt={item.caption} />
              </ClickableDiv>
            ))}
          </Masonry>
        </div>
      </section>

      <Lightbox
        open={index > -1}
        close={() => setIndex(-1)}
        index={index}
        slides={portfolioJson.items.map((item, i) => ({
          src: item.imgSrc,
          description: item.caption,
        }))}
        plugins={[Captions]}
      />
    </Layout>
  );
};

export default PortfolioPage;
